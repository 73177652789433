import React from 'react';

import Home from './Home';
import AboutUs from './AboutUs';
import Contact from './Contact';
import Footer from './Footer';


function App() {
  return (
    <>
      <main>
        <Home />
        <AboutUs />
        <Contact />
        <Footer />
      </main>
    </>
  );
}

export default App;
