// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="p-4 bg-gray-800 text-center text-white">
      <p>© 2024 Lord of the Frog. All rights reserved.</p>
    </footer>
  );
}

export default Footer;
