import React from 'react';

const AboutUs = () => {
  return (
    <>
      <section id="about" className="flex flex-col items-center justify-center h-screen bg-green-500 ps-2 pe-2">
        <div className="px-4 max-w-lg text-center text-white">
          <h1 className="text-2xl md:text-3xl lg:text-6xl font-bold mb-4 sm:mb-8">Heqet: Egyptian Goddess of Fertility</h1>
          <p className="text-lg md:text-xl lg:text-4xl">Heqet, an Egyptian goddess of fertility, often linked with Hathor and symbolized by a frog, was associated with the annual flooding of the Nile. Initially a counterpart to Khnum, she's speculated to have influenced the naming of Hecate, the Greek goddess of witchcraft.</p>
        </div>
      </section>

      {/* New Section: Token Information */}
      <section id="token-info" className="flex flex-col items-center justify-center bg-pink-300 h-screen">
        <div className="px-8 max-w-lg text-center">


          <div className="flex flex-col sm:flex-row mb-24 justify-center items-center">
            <div className="text-gray-700 text-lg text-xl lg:text-4xl sm:text-2xl sm:text-2xl px-4 py-2">Ticker $HEQET</div>
            <div className="text-gray-700 text-lg text-xl lg:text-4xl sm:text-2xl sm:text-2xl px-4 py-2">Liquidity BURNT</div>
            <div className="text-gray-700 text-lg text-xl lg:text-4xl sm:text-2xl sm:text-2xl px-4 py-2">Mint REVOKED</div>
          </div>

          <div className="flex flex-col justify-center items-center mt-8">
            <div className="text-2xl md:text-3xl lg:text-6xl font-bold mb-4 sm:mb-8">Tokenomics</div>
            <div className="flex flex-col sm:flex-row justify-center items-center">
              <div className="text-gray-700 text-lg md:text-xl lg:text-4xl sm:text-2xl px-4 py-2">LP 80%</div>
              <div className="text-gray-700 text-lg md:text-xl lg:text-4xl sm:text-2xl px-4 py-2">CEX 10%</div>
              <div className="text-gray-700 text-lg md:text-xl lg:text-4xl sm:text-2xl px-4 py-2">Team 5%</div>
              <div className="text-gray-700 text-lg md:text-xl lg:text-4xl sm:text-2xl px-4 py-2">Marketing 5%</div>
            </div>
          </div>
        </div>
      </section>

      <section id="howtobuy" className="flex flex-col items-center justify-center h-screen bg-violet-400 ">
        <div className="px-4 max-w-lg text-center text-white">
          <h1 className="text-2xl md:text-3xl lg:text-6xl font-bold mb-4 sm:mb-8">How To Buy?</h1>
          <p className="text-lg mb-4 md:text-xl lg:text-4xl">1. Download Phantom Wallet Create a Phantom wallet on browser extension or on mobile apps.
          </p>
          <p className="text-lg mb-4 md:text-xl lg:text-4xl">2. Buy SOL Ensure you have SOL in your Phantom wallet. Purchase SOL from a cryptocurrency exchange to transfer SOL to your Phantom wallet.
          </p>
          <p className="text-lg md:text-xl lg:text-4xl">3. Swap Use Raydium to purchase $HEQET. Paste the contract adress to swap your SOL into $HEQET.
          </p>


        </div>
      </section>



    </>
  );
}

export default AboutUs;
