// Home.js
import React from 'react';
import heqetbig from "./assets/heqetmeme.png";

const Home = () => {
  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-yellow-300">

      <section className="flex flex-col items-center w-full mt-8">
        <div className="pink-ribbon bg-pink-500 w-full h-12 absolute top-0 left-0"></div>
        <div className="flex justify-center items-center w-full">
          <h1 className="text-black font-bold py-4 px-8 text-4xl md:text-7xl">HEQET</h1>

          <div className="flex flex-wrap justify-center">
            <a href="">
              <button className="bg-white text-black font-bold py-4 px-8 rounded-lg shadow-lg m-2 md:text-lg">$ HEQET</button>
            </a>
            <a href="#about">
              <button className="bg-white text-black font-bold py-4 px-8 rounded-lg shadow-lg m-2 md:text-lg">ABOUT</button>
            </a>
            <a href="mailto:project@heqetmeme.com">
              <button className="bg-white text-black font-bold py-4 px-8 rounded-lg shadow-lg m-2 md:text-lg">CONTACT </button>
            </a>
            <a href="https://twitter.com/heqetmeme">
              <button className="bg-white text-black font-bold py-4 px-8 rounded-lg shadow-lg m-2 md:text-lg">TWITTER</button>
            </a>
            <a href="https://t.me/heqetmeme">
              <button className="bg-white text-black font-bold py-4 px-8 rounded-lg shadow-lg m-2 md:text-lg">TELEGRAM</button>
            </a>
          </div>
        </div>
        <div className="w-full flex flex-col md:flex-row justify-center items-center -mb-8">
          <img src={heqetbig} alt="Heqet" className="object-cover w-full md:w-1/2 h-auto md:h-full" />
        </div>

      </section>
    </div>
  );
}

export default Home;
