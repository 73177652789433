import React from 'react';
import { GiClick } from "react-icons/gi";

const Contact = () => {
  return (
    <section className="flex flex-col items-center justify-center h-screen bg-blue-500">
      <h2 className="text-5xl sm:text-8xl font-bold text-white mb-4 sm:mb-8">Contact Us</h2>
      
      <a href="mailto:heqetmeme@gmail.com" className="flex items-center text-4xl sm:text-6xl text-white mb-2 sm:mb-4">
        hello@heqetmeme.com
        <GiClick className="text-3xl sm:text-6xl"/>
      </a>
    </section>
  );
}

export default Contact;
